import React, { useState } from 'react';
import './StoreClosureForm.css';

const StoreClosureForm = () => {
  const [store, setStore] = useState('');
  const [reason, setReason] = useState('');
  const [duration, setDuration] = useState('');
  const [mailtoLink, setMailtoLink] = useState('');
  const [showPreview, setShowPreview] = useState(false);
  const [showInstructions, setShowInstructions] = useState(false);
  const [to, setTo] = useState('susan@everwash.com');
  const [cc, setCc] = useState('markl@everwash.com,alex.genson@cleanviewautowash.com,daniel.brown@cleanviewautowash.com,dany.toma@cleanviewautowash.com,frank.toma@cleanviewautowash.com');
  const [subject, setSubject] = useState('Clean View Auto Wash Closure');
  const [body, setBody] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    const emailBody = `Hi Susan and Mark,\n\nCV${store} is closing early today due to ${reason}.\nIt is expected to be closed for ${duration}.\nWe are working to open back up ASAP.`;
    setBody(emailBody);
    const mailto = `mailto:${to}?cc=${cc}&subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(emailBody)}`;
    setMailtoLink(mailto);
    setShowPreview(true);
  };

  const toggleInstructions = () => {
    setShowInstructions(!showInstructions);
  };

  return (
    <div className="form-container">
      <form onSubmit={handleSubmit} className="closure-form">
        <label>
          Store:
          <select value={store} onChange={(e) => setStore(e.target.value)} required>
            <option value="">Select store</option>
            {Array.from({ length: 10 }, (_, i) => (
              <option key={i} value={i + 1}>{`CV${i + 1}`}</option>
            ))}
          </select>
        </label>
        <label>
          Why is the store closing?
          <textarea value={reason} onChange={(e) => setReason(e.target.value)} required />
        </label>
        <label>
          How long is it expected to be closed for?
          <textarea value={duration} onChange={(e) => setDuration(e.target.value)} required />
        </label>
        <button type="submit" className="submit-button">Generate Email</button>
      </form>
      {showPreview && (
        <div className="email-preview">
          <h2>Email Preview</h2>
          <label>
            To:
            <input type="text" value={to} onChange={(e) => setTo(e.target.value)} />
          </label>
          <label>
            CC:
            <input type="text" value={cc} onChange={(e) => setCc(e.target.value)} />
          </label>
          <label>
            Subject:
            <input type="text" value={subject} onChange={(e) => setSubject(e.target.value)} />
          </label>
          <label>
            Body:
            <textarea value={body} onChange={(e) => setBody(e.target.value)} />
          </label>
          <a href={mailtoLink} className="send-button">Send Email</a>
        </div>
      )}
      <div className="instructions-link">
        <button onClick={toggleInstructions} className="instructions-toggle">
          How to set your default mail app
        </button>
        {showInstructions && (
          <div className="default-mail-app-instructions">
            <h2>How to Set Default Mail App</h2>
            <h3>iOS</h3>
            <p>1. Open the Settings app on your iOS device.</p>
            <p>2. Scroll down and tap on the mail app (e.g., Gmail) you want to set as default.</p>
            <p>3. Tap "Default Mail App".</p>
            <p>4. Select the mail app you want to use.</p>
            <h3>Android (Google Play)</h3>
            <p>1. Open the Settings app on your Android device.</p>
            <p>2. Tap on "Apps & notifications".</p>
            <p>3. Tap on "Advanced" to expand the options.</p>
            <p>4. Tap on "Default apps".</p>
            <p>5. Tap on "Email app".</p>
            <p>6. Select the mail app you want to use (e.g., Gmail).</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default StoreClosureForm;
